import _collection from "../internals/collection";
import _collectionWeak from "../internals/collection-weak";
var collection = _collection;
var collectionWeak = _collectionWeak; // `WeakSet` constructor
// https://tc39.es/ecma262/#sec-weakset-constructor

collection("WeakSet", function (init) {
  return function WeakSet() {
    return init(this, arguments.length ? arguments[0] : undefined);
  };
}, collectionWeak);
export default {};